import React from 'react';
import styled from 'styled-components';

const PropertyStyle = styled.div`
  position: relative;
  .saedberg {
    object-fit: contain;
    width: 100%;
    padding: 2em 0 2em;
  }

  @media all and(min-width:600px) {
    .saedberg {
      object-fit: contain;
      width: 100%;
      padding: 3em;
    }
  }
  .kommerforsalg {
    position: absolute;
    width: 72px;
    height: 72px;
    right: 18px;
    top: 32px;
  }
`;
const Property: React.FC = ({ children }) => {
  return (
    <PropertyStyle>
      <img
        className="saedberg"
        src="https://cdn.reeltime.no/pm_assets/kampanjer/saedberg/saedbergenebolig.jpg"
        alt=""
      />
      {children}
    </PropertyStyle>
  );
};

export default Property;
