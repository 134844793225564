import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { SiteContext } from '../../../utils/context/SiteContext';
import { routeConfig } from '../../../__config__/routes';
import { Seo } from '../../../utils/seo/Index';
import { Thanks } from '../../layout/Thanks';
import { Section } from '../../layout/Section';
import Property from './Property';

const Saedberg: React.FC = () => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const [posted, setPosted] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 0000',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      /*       {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse *',
        required: true
      }, */
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Skriv kommentar',
        value: '',
        label: 'Kommentar'
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: 'EPOST',
            mailType: 85
          }
        }
      });
    }
  });

  return (
    <MaripaaveienStyle>
      <Seo
        title="PrivatMegleren Sædberg % Lian - Enebolig med ettertraktet beliggenhet"
        description="Enebolig med ettertraktet beliggenhet - Fantastisk utsikt – Egen privat båtplass (3,75 m) - Solrikt uteområde med isolert utestue – Dobbel garasje"
        image="https://cdn.reeltime.no/pm_assets/kampanjer/saedberg/saedbergenebolig.jpg"
      />

      <Container>
        <Heading tag="h1" center={true}>
          Enebolig med ettertraktet beliggenhet
        </Heading>
        <Property>
          {/* Dette kan sikkert gjøres mer effektivt med bruk av alias i webpack, men siden ønskes lansert denne uken (uke 16 2023) */}
          <img
            className="kommerforsalg"
            src="https://cdn.reeltime.no/pm_assets/kampanjer/saedberg/forsalg.png"
            alt=""
          />
        </Property>

        <Info>
          <Paragraph center={true} style={{ paddingBottom: '3em' }}>
            Fantastisk utsikt <br />
            Egen privat båtplass (3,75 m) <br />
            Solrikt uteområde med isolert utestue <br />
            Dobbel garasje <br />
          </Paragraph>
        </Info>
        <FormWrapper style={fadeOut}>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter((item) => item?.type !== 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${item?.name}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {/*             {fields
              .filter((item) => item?.type === 'textarea')
              .map((item) => (
                <FormRow key={`field_${item?.name}`}>
                  <Textarea
                    name={item?.name}
                    label={item?.label}
                    labelAfter={item?.labelAfter}
                    placeholder={item?.placeholder}
                    value={item?.value}
                    onChange={handleChange}
                    error={item?.error}
                  />
                </FormRow>
              ))} */}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Kontakt meg
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </MaripaaveienStyle>
  );
};

const MaripaaveienStyle = styled.div`
  padding-top: 7em;
  .saedberg {
    object-fit: contain;
    width: 100%;
    padding: 2em 0 2em;
  }

  @media all and(min-width:600px) {
    .saedberg {
      object-fit: contain;
      width: 100%;
      padding: 3em;
    }
  }
`;

const Info = styled.div`
  li {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default Saedberg;
